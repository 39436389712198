<template lang="">
  <div>
    <!-- SECTION Card ket qua -->
    <BCard header-class="py-1">
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('packageConfig.agencyPackageOrderList') }}
        </div>
      </template>

      <!-- FILTER AND BUTTON -->
      <BRow class="mb-50">
        <!-- Order Code -->
        <BCol
          cols="12"
          md="3"
          lg="2"
        >
          <b-form-group
            label-for="order-code-filter"
            label-class="h6"
            :label="$t('packageConfig.columns.orderCode')"
            class="w-100 mb-0 mt-25 mt-lg-0"
          >
            <BFormInput
              id="order-code-filter"
              v-model="orderCodeFilter"
              debounce="300"
              :placeholder="$t('packageConfig.columns.orderCode')"
            />
          </b-form-group>
        </BCol>
        <!-- Agency -->
        <BCol
          v-if="!isRoleF2"
          cols="12"
          md="3"
          lg="2"
        >
          <b-form-group
            label-for="agency-code-filter"
            label-class="h6"
            :label="$t('packageConfig.columns.agency')"
            class="w-100 mb-0 mt-25 mt-lg-0"
          >
            <v-select
              id="agency-code-filter"
              v-model="agencyCodeFilter"
              class="w-100"
              style="font-size: 1rem"
              :options="agencyOptions"
              label="agencyCode"
              clearable
              :loading="loadingAgencies"
              :placeholder="$t('packageConfig.placeholder.agency')"
              :reduce="(val) => val.agencyCode"
              @open="handleOpenAgency"
              @search="handleSearchAgency"
            >
              <template #selected-option="{ agencyName, agencyCode }">
                <div style="width: 100%">
                  <span class="d-block font-weight-bold text-truncate">
                    {{ agencyCode }} <small>({{ agencyName }})</small>
                  </span>
                </div>
              </template>
              <template #option="{ agencyName, agencyCode }">
                <div style="width: 100%">
                  <span class="d-block font-weight-bold text-truncate">
                    {{ agencyCode }} <small>({{ agencyName }})</small>
                  </span>
                </div>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
              <template #no-options>
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
                <div v-else>
                  {{ $t('noOptions') }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </BCol>
        <!-- Package config -->
        <BCol
          cols="12"
          md="3"
          lg="2"
        >
          <b-form-group
            label-for="package-config-filter"
            label-class="h6"
            :label="$t('packageConfig.columns.packageConfig')"
            class="w-100 mb-0 mt-25 mt-lg-0"
          >
            <v-select
              id="package-config-filter"
              v-model="packageConfigIdFilter"
              class="w-100"
              style="font-size: 1rem"
              :options="packageConfigOptions"
              label="name"
              clearable
              :loading="loadingPackageConfigs"
              :placeholder="$t('packageConfig.columns.packageConfig')"
              :reduce="(val) => val.id"
              @open="handleOpenPackageConfig"
              @search="handleSearchPackageConfig"
            >
              <template #selected-option="{ name, price }">
                <div style="width: 100%">
                  <span class="d-block font-weight-bolder text-truncate">
                    {{ name }} <small>({{ formatCurrency(price) }})</small>
                  </span>
                </div>
              </template>
              <template #option="{ name, price }">
                <div style="width: 100%">
                  <span class="d-block font-weight-bolder text-truncate">
                    {{ name }} <small>({{ formatCurrency(price) }})</small>
                  </span>
                </div>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
              <template #no-options>
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
                <div v-else>
                  {{ $t('noOptions') }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </BCol>

        <!-- Order status -->
        <BCol
          cols="12"
          md="3"
          lg="2"
        >
          <b-form-group
            label-for="order-status-filter"
            label-class="h6"
            :label="$t('packageConfig.columns.enable')"
            class="w-100 mb-0 mt-25 mt-lg-0"
          >
            <v-select
              id="order-status-filter"
              v-model="orderStatusFilter"
              class="w-100"
              style="font-size: 1rem"
              :options="orderStatusOptions"
              label="name"
              clearable
              :placeholder="$t('packageConfig.columns.enable')"
              :reduce="(val) => val"
            >
              <template #selected-option="{ name }">
                <div style="width: 100%">
                  <span class="d-block font-weight-bolder text-truncate">
                    {{ $t(`packageConfig.${name}`) }}
                  </span>
                </div>
              </template>
              <template #option="{ name }">
                <div style="width: 100%">
                  <span class="d-block font-weight-bolder text-truncate">
                    {{ $t(`packageConfig.${name}`) }}
                  </span>
                </div>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
              <template #no-options>
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
                <div v-else>
                  {{ $t('noOptions') }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </BCol>

        <!-- Payment method -->
        <BCol
          cols="12"
          md="3"
          lg="2"
        >
          <b-form-group
            label-for="payment-method-filter"
            label-class="h6"
            :label="$t('packageConfig.columns.paymentMethod')"
            class="w-100 mb-0 mt-25 mt-lg-0"
          >
            <v-select
              id="payment-method-filter"
              v-model="paymentMethodFilter"
              class="w-100"
              style="font-size: 1rem"
              :options="paymentMethodOptions"
              label="name"
              clearable
              :placeholder="$t('packageConfig.columns.paymentMethod')"
              :reduce="(val) => val"
            >
              <template #selected-option="{ name }">
                <div style="width: 100%">
                  <span class="d-block font-weight-bolder text-truncate">
                    {{ $t(`packageConfig.${name}`) }}
                  </span>
                </div>
              </template>
              <template #option="{ name }">
                <div style="width: 100%">
                  <span class="d-block font-weight-bolder text-truncate">
                    {{ $t(`packageConfig.${name}`) }}
                  </span>
                </div>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
              <template #no-options>
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
                <div v-else>
                  {{ $t('noOptions') }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </BCol>

        <!-- button -->
        <BCol
          cols="12"
          md="3"
          lg="2"
        >
          <div class="d-flex justify-content-end align-items-end h-100">
            <BButton
              variant="flat-danger"
              class="p-1"
              @click="clearFilter"
            >
              Xoá bộ lọc
            </BButton>
          </div>
        </BCol>
      </BRow>

      <!-- TABLE -->
      <BTable
        ref="refTable"
        style="max-height: calc(100vh - 400px); height: calc(100vh - 400px)"
        :sticky-header="true"
        class="position-relative"
        :items="resultArray"
        responsive
        :striped="true"
        :fields="tableColumnsRole"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        :busy="loading"
        :hover="true"
        :borderless="true"
      >
        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>

        <template
          v-for="column in tableColumnsRole"
          #[`head(${column})`]=""
        >
          <span
            :key="column"
            class="text-dark text-nowrap"
          >
            {{ $t(`packageConfig.columns.${column}`) }}
          </span>
        </template>

        <template #cell(orderCode)="{ item }">
          <div class="font-weight-bolder">
            {{ item.orderCode }}
          </div>
        </template>

        <template #cell(packageConfig)="{ item }">
          <div>{{ item.packageConfig.name }} x{{ item.monthsUse }} tháng</div>
          <em>({{ formatCurrency(item.packagePrice) }} <small>VND</small>)</em>
        </template>

        <template #cell(buyer)="{ item }">
          <div>
            <div class="font-weight-bolder">
              {{ item.buyer?.agencyCode }}
            </div>
            <small class="font-italic">({{ item.buyer?.agencyName }})</small>
          </div>
        </template>
        <template #cell(agencyRetail)="{ item }">
          <div>
            <div class="font-weight-bolder">
              {{
                item?.agencyRetail
                  ? item?.agencyRetail?.agencyCode
                  : 'Áp dụng cho đại lý mới'
              }}
            </div>
            <small
              v-if="item.agencyRetail?.agencyName"
              class="font-italic"
            >({{ item.agencyRetail?.agencyName }})</small>
          </div>
        </template>

        <template #cell(payment)="{ item }">
          <div>
            <del
              v-if="item.totalDiscount"
              class="font-small-4 font-italic text-danger"
            >
              {{ formatCurrency(item.totalPrice) }} <small>VND</small>
            </del>

            <div class="font-weight-bolder">
              {{ formatCurrency(item.totalPrice - (item?.totalDiscount || 0)) }}
              <small>VND</small>
            </div>
            <BBadge
              pill
              :variant="
                item.orderStatus === 'DONE'
                  ? 'light-success'
                  : item.orderStatus === 'PENDING'
                    ? 'light-warning'
                    : 'light-danger'
              "
            >
              {{ $t(`packageConfig.${item.orderStatus}`) }}
            </BBadge>
            <div>
              <em class="font-weight-bolder">
                {{ $t(`packageConfig.${item.paymentMethod}`) }}
              </em>
            </div>
          </div>
        </template>

        <template #cell(action)="row">
          <div class="d-flex-between gap-2">
            <div class="d-flex gap-2">
              <BButton
                variant="flat-primary"
                size="sm"
                @click="row.toggleDetails"
              >
                Chi tiết
              </BButton>
              <BButton
                v-if="row.item.orderStatus === 'PENDING'"
                variant="success"
                size="sm"
                @click="paymentOrderHandle(row.item)"
              >
                {{ $t('packageConfig.columns.payment') }}
              </BButton>
            </div>
            <BButton
              v-if="row.item.orderStatus === 'PENDING'"
              variant="flat-danger"
              size="sm"
              @click="cancelOrderHandle(row.item)"
            >
              {{ $t('packageConfig.cancel') }}
            </BButton>
          </div>
        </template>

        <template
          #row-details="{
            item: {
              packageConfig,
              totalPrice,
              totalDiscount,
              monthsExtra,
              totalAmountToBePaid,
              paymentMethod,
              createdAt,
              updatedAt,
              packageCoupon,
            },
          }"
        >
          <div class="d-flex gap-3 align-items-stretch">
            <PackageDetail :options="packageConfig.descriptions" />
            <b-card
              style="width: 30%"
              class="border mb-0"
              body-class="py-1 px-2"
            >
              <h4 class="font-weight-bolder text-dark">
                Thông tin thanh toán
              </h4>
              <div
                v-for="(value, key) of {
                  totalPrice,
                  totalDiscount,
                  monthsExtra,
                  totalAmountToBePaid,
                  paymentMethod,
                  createdAt,
                  updatedAt,
                }"
                :key="key"
                class="my-50"
              >
                <div class="d-flex-between font-weight-bolder">
                  <div>
                    <div class="d-flex gap-1">
                      <div>{{ $t(`packageConfig.columns.${key}`) }}:</div>
                      <div v-if="key === 'totalDiscount' && packageCoupon?.code">
                        <kbd class="text-dark">{{ packageCoupon?.code }}</kbd>
                      </div>
                    </div>
                    <div v-if="key === 'totalDiscount' && packageCoupon?.code">
                      <small
                        v-if="packageCoupon?.percentage"
                        class="font-italic"
                      >
                        Giảm giá {{ packageCoupon.percentage }}%
                        <span v-if="packageCoupon.amountMax">(tối đa
                          {{ formatCurrency(packageCoupon.amountMax) }}
                          VND)</span>
                      </small>
                      <small
                        v-else-if="packageCoupon?.amountMax"
                        class="font-italic"
                      >
                        Giảm giá
                        {{ formatCurrency(packageCoupon.amountMax) }} VND
                      </small>
                    </div>
                  </div>
                  <div v-if="key === 'paymentMethod'">
                    <span class="">{{ $t(`packageConfig.${value}`) }}</span>
                  </div>
                  <div v-else-if="['createdAt', 'updatedAt'].includes(key)">
                    <span class="">{{
                      convertISODateTime(value).dateTime
                    }}</span>
                  </div>
                  <div
                    v-else-if="
                      [
                        'totalPrice',
                        'totalAmountToBePaid',
                        'totalDiscount',
                      ].includes(key)
                    "
                  >
                    <span class="">{{ formatCurrency(value) }}</span>
                  </div>
                  <div v-else>
                    <span class="">{{ value }}</span>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </template>
        <template
          slot="bottom-row"
          slot-scope="data"
        >
          <td
            v-for="(field, i) in data.fields"
            :key="i"
            style="font-size: 18px"
          >
            <div v-if="field.key === 'payment'">
              {{ formatCurrency(totalColumn(field.key)) || 0 }}
            </div>
            <div
              v-if="field.key === 'orderCode'"
              class="text-uppercase"
            >
              TỔNG
            </div>
          </td>
        </template>
      </BTable>

      <!-- ANCHOR: Table Footer -->
      <b-row class="d-flex-center justify-content-md-between">
        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center mb-50 mb-md-0"
        >
          <span class="font-weight-bolder">
            {{ $t('paginationText.showing') }}
          </span>
          <v-select
            v-model="sizePerPage"
            :options="sizePerPageLgOptions"
            :clearable="false"
            :append-to-body="true"
            :calculate-position="withPopper"
            class="per-page-selector d-inline-block mx-50"
          >
            <template #option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
          <span class="font-weight-bolder">
            {{ $t('paginationText.from') }} {{ fromItem }}
            {{ $t('paginationText.to') }} {{ toItem }}
            {{ $t('paginationText.outOf') }} {{ totalItems }}
          </span>
        </b-col>

        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="sizePerPage"
            first-number
            last-number
            class="mb-0 mt-25 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </BCard>
    <!-- !SECTION -->

    <ModalBuy
      :buy-item-props="paymentItem"
      :agency-item="agencyItem"
      :qr-code="qrCode"
      :payment-method-prop="paymentMethod"
      :months-use-prop="monthsUse"
      :is-payment="true"
    />
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BPagination,
  BBadge,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'
import { ref, computed, nextTick } from '@vue/composition-api'

import { sizePerPageLgOptions } from '@/constants/selectOptions'
import store from '@/store'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'

import useAgencyPackgageOrder from './useAgencyPackageOrder'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BPagination,
    BBadge,
    BFormGroup,
    BFormInput,
    ModalBuy: () => import('@/views/retail-package/components/ModalBuy.vue'),
    vSelect: () => import('vue-select'),
    PackageDetail: () => import('@/views/retail-package/components/PackageDetail.vue'),
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup(_, { root }) {
    const {
      agencyCodeFilter,
      orderCodeFilter,
      packageConfigIdFilter,
      orderStatusFilter,
      paymentMethodFilter,
      resultArray,
      currentPage,
      sizePerPage,
      loading,
      totalItems,
      fromItem,
      toItem,
      fetchAgencyPackageOrder,
      tableColumns,
      handleOpenAgency,
      handleSearchAgency,
      agencyOptions,
      loadingAgencies,
      packageConfigOptions,
      handleOpenPackageConfig,
      handleSearchPackageConfig,
      loadingPackageConfigs,
      orderStatusOptions,
      paymentMethodOptions,
      clearFilter,
      cancelOrder,
    } = useAgencyPackgageOrder()

    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    fetchAgencyPackageOrder()

    const tableColumnsRole = isRoleF2.value
      ? tableColumns.value.filter(item => !['buyer'].includes(item))
      : tableColumns.value

    const paymentItem = ref(null)
    const qrCode = ref()
    const paymentMethod = ref()
    const monthsUse = ref()

    const agencyItem = ref(null)
    function paymentOrderHandle(item) {
      paymentItem.value = item?.packageConfig
      agencyItem.value = item?.agencyRetail
      qrCode.value = item?.qrCodeUrl
      paymentMethod.value = item?.paymentMethod
      monthsUse.value = item?.monthsUse
      nextTick(() => {
        this.$bvModal.show('modal-buy-package-config')
      })
    }

    function cancelOrderHandle(item) {
      const titleMsg = this.$createElement('div', {
        domProps: {
          innerHTML: `
          Bạn chắc chắn muốn huỷ
            <span class="font-weight-bolder">
              ${item.orderCode}
              <span class="font-italic">
                (${formatCurrency(item.totalPrice)} VND)
                </span>
              </span>?`,
        },
      })
      root.$bvModal
        .msgBoxConfirm([titleMsg], {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            await cancelOrder(item.id)
            fetchAgencyPackageOrder()
          }
        })
    }

    const totalColumn = key => {
      if (key === 'payment') { return resultArray.value.reduce(
        (sum, item) => sum + item.totalAmountToBePaid,
        0,
      ) }
      return 0
    }

    return {
      loading,
      resultArray,
      tableColumnsRole,
      totalColumn,

      sizePerPageLgOptions,
      currentPage,
      sizePerPage,
      totalItems,
      fromItem,
      toItem,
      formatCurrency,
      fetchAgencyPackageOrder,

      paymentOrderHandle,
      paymentItem,
      isRoleF2,

      handleOpenAgency,
      handleSearchAgency,
      agencyOptions,
      loadingAgencies,
      agencyItem,

      agencyCodeFilter,
      orderCodeFilter,
      packageConfigIdFilter,
      orderStatusFilter,
      paymentMethodFilter,

      packageConfigOptions,
      handleOpenPackageConfig,
      handleSearchPackageConfig,
      loadingPackageConfigs,
      orderStatusOptions,
      paymentMethodOptions,
      clearFilter,
      cancelOrderHandle,
      qrCode,
      paymentMethod,
      monthsUse,
      convertISODateTime,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.b-table-bottom-row {
  position: sticky;
  bottom: 0;
  background-color: #e1eef5;
  color: $primary;
}
</style>
